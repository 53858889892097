/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<g _stroke="#1C1E42" stroke-width="1.6" _fill="none" fill-rule="evenodd" stroke-linecap="square"><path pid="0" d="M2 13.314L13.314 2M13.314 13.314L2 2"/></g>'
  }
})
